<template>
  <img src="@assets/images/individualBusiness/tax-password-example.png" alt='example' />
</template>

<script>
  export default {
    name: 'taxPasswordExample',
    data() {
      return {}
    },
    mounted() {
    },
    methods: {
    }
  }
</script>

<style lang='less' scoped>
</style>